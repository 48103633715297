<div *ngIf="shouldShowFacilitySelect" class="d-flex align-items-center flex-grow-1">
    <fal i="building" class="text-primary mr-1"></fal>
    <ng-container *ngIf="multi">
        <app-advanced-input type="select"
                            [(ngModel)]="orgFac"
                            (ngModelChange)="onFacilityChange($event)"
                            [selectItems]="facilityOptions"
                            [multi]="true"
                            [placeholder]="placeholder"
                            groupBy="organizationName"
                            [class]="classes + ' flex-grow-1'"
                            [compareWith]="compareOnlyByFacilityId">
            <ng-template ng-option-tmp let-item=item>
                <ng-container *ngTemplateOutlet="optionTmp; context: {item: item}"></ng-container>
            </ng-template>
        </app-advanced-input>
    </ng-container>
    <ng-container *ngIf="!multi">
        <app-advanced-input type="select"
                            style="min-width: 200px !important;"
                            [ngModel]="orgFac"
                            (ngModelChange)="onFacilityChange($event)"
                            [selectItems]="facilityOptions"
                            [placeholder]="placeholder"
                            groupBy="organizationName"
                            [class]="classes + ' flex-grow-1'"
                            [compareWith]="compareFacilityObjById">
            <ng-template ng-option-tmp let-item=item>
                <ng-container *ngTemplateOutlet="optionTmp; context: {item: item}"></ng-container>
            </ng-template>
        </app-advanced-input>
    </ng-container>
</div>

<ng-template #optionTmp let-item="item">
    <span [style.opacity]="item.facility?.is_inactive ? '.5' : null">{{item.name}}</span>
</ng-template>
